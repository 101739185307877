import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import HeroImage from "../components/HeroImage";
import FullWidthInfo from "../components/FullWidthInfo";
import ImageGallery from "../components/ImageGallery";

const IndexPage = ({ data }) => {
  return (
    <Layout alwaysLight={false} showContactForm={false}>
      <main>
        <SEO title="Home" />
        <HeroImage
          image={data.images.nodes.filter(image => image.name === "hero")}
        />
        <FullWidthInfo
          headline="About Us"
          body="Our experienced staff are well versed in combining the benefits of modern materials and traditional techniques to carry out even the most demanding roofing project. We pride ourselves on our high level of workmanship, whilst never negating the importance of keeping a clean site and keeping customers up to date with progress. Skilled in a vast array of roofing techniques our staff are equally adept at helping you with a small repair as they are with a strip and re-roof. We're big enough to cope but small enough to care."
          linkTo="/contact/"
          linkCopy="Call us now to talk about a job"
        />
        <ImageGallery
          images={data.images.nodes.filter(image => image.name === "image1")}
        />
        <FullWidthInfo
          headline="Our Services"
          body="We provide a extensive range of roofing services to meet your demands. From tiling to slating, flat roofing to UPVC fascias, soffits and guttering, leadwork to chimney services, complete re-roofs to small repairs, our staff have the neccessary skills for even the most demanding of problems."
          linkTo="/services/"
          linkCopy="View all of our services"
        />
      </main>
    </Layout>
  );
};

export const query = graphql`
  query HomeImages {
    images: allFile(filter: { relativeDirectory: { eq: "home" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default IndexPage;
