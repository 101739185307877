import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

import heroLogo from "../../images/home/hero-logo.png";

const StyledHeroImage = styled.div`
  min-height: 350px;
  background-color: #c1c1c1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 740px) {
  }

  @media only screen and (min-width: 920px) {
    min-height: 550px;
    text-align: center;
  }

  .bgImg {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  img.logo {
    width: 100%;
    max-width: 280px;
    z-index: 1;

    @media only screen and (min-width: 740px) {
      max-width: 350px;
    }

    @media only screen and (min-width: 920px) {
      max-width: 500px;
    }
  }
`;

const HeroImage = ({ image }) => {
  return (
    <StyledHeroImage>
      {image.map(img => (
        <Img
          className="bgImg"
          fluid={img.childImageSharp.fluid}
          alt="Agace Bros hero"
        />
      ))}
      <img className="logo" alt="Agace Bros logo" src={heroLogo} />
    </StyledHeroImage>
  );
};

export default HeroImage;
